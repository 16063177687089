import React from 'react';
import logo from './logo.svg';
import './App.css';
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCAp0bfNWuEfeN769URgPp6ndEYBrjvz_k",
  authDomain: "pwa-test-a8ec1.firebaseapp.com",
  projectId: "pwa-test-a8ec1",
  storageBucket: "pwa-test-a8ec1.appspot.com",
  messagingSenderId: "872032551246",
  appId: "1:872032551246:web:ecfca6c2a5c567c6e99e1f"
};

// Initialize Firebase
initializeApp(firebaseConfig);

function copyToClipboard (value: string) {
  if (navigator.clipboard) {
    return navigator.clipboard.writeText(value);
  }
}

// FCMテスト（画面のボタン押下時に実行）
function push_test() {
  const messaging = getMessaging();
  navigator.serviceWorker.register('./firebase-messaging-sw.js').then(registration => {
    getToken(messaging, {
      vapidKey: 'BOVSURVUBH3DLIvj71OvmOHFx2kVT_JReEWcf87PMIqXfjVkZ7uMGq6-sD4oufSaJNYJWduOBvY8lSR1yDQHJvQ', // ウェブプッシュ証明書のキーを貼り付ける
      serviceWorkerRegistration: registration //サービスワーカを指定（省略するとルートディレクトリのfirebase-messaging-sw.jsを登録しようとする）
    }).then((currentToken) => {
      console.debug('token is ' + currentToken);
      if (currentToken) {
        // 省略：トークンをサーバに送ってDBに登録する（そうしないとトークンはサーバ側で分からない）
        copyToClipboard(currentToken);
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
  }).catch(err => {
    console.log('ServiceWorker registration failed.');
  });
}

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <button
          className="App-link"
          onClick={push_test}
        >
          push_test
        </button>
      </header>
    </div>
  );
}

export default App;
